import { List, Show, Edit, FilterButton, useNotify, Create, required } from 'react-admin'
import { useWatch } from "react-hook-form";
import { Datagrid, FormDataConsumer, DateField, DateInput, TextField, AutocompleteInput, NumberField, NumberInput, ReferenceInput, SelectField, SelectInput, PasswordInput, BooleanField, EmailField, BooleanInput, SimpleShowLayout, TopToolbar, ListButton, ShowButton, SimpleForm, TextInput} from 'react-admin';
import moment from 'moment';
import licenseTypes from './licenseTypes';
import {PerpetualVersionList, PerpetualVersionChoices} from './perpetualVersions';

const LicenseListActions = () => (
    <TopToolbar>
    </TopToolbar>
);

const licenseFilters = [
    <TextInput label="Email" source="email" defaultValue="" alwaysOn/>,
    <SelectInput label="Subject" source="subject" defaultValue="" alwaysOn choices={licenseTypes}/>,
];

export const LicenseList = () => (
    <List filters={licenseFilters}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id"/>
            <EmailField source="email"/>
            <TextField source="subject"/>
            <DateField source="startDate"/>
            <DateField source="expirationDate"/>
            <NumberField source="numSeats"/>
        </Datagrid>
    </List>
);

const today = () => {
    const today = new Date();
    const numberOfDaysToAdd = 3;
    const date = today.setDate(today.getDate() + numberOfDaysToAdd); 
    const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
    return defaultValue;
}

const filterToQuery = email => ({ email: email });

const hasDuration = (subject) => {
    return !['LIFETIME', 'EDULAB', 'MUSIO_ONE', 'MUSIO_ONE_UPGRADE'].includes(subject)
}

const isMusio1 = (subject) => {
    return ['MUSIO_ONE', 'MUSIO_ONE_UPGRADE'].includes(subject)
}

const Musio1VersionInput = (props) => (
    <SelectInput label="Musio 1 Version" choices={PerpetualVersionChoices} defaultValue={PerpetualVersionList.length} {...props}/>
);

export const LicenseCreate = (props) => {
    const transform = data => {
        let start_date = data.startDate ? moment(data.startDate) : moment();
        let new_start_date = start_date.format('YYYY-MM-DD')
        let ret_data = {
            userId: data.userId,
            subject: data.subject,
            startDate: new_start_date,
        }

        if (!data.noExpiration && hasDuration(data.subject)) {
            let new_end_date = start_date;
            if (data.duration == 'years'){
                new_end_date = start_date.add(data.count, 'years')
            }
            else if (data.duration == 'months'){
                new_end_date = start_date.add(data.count, 'months')
            }
            else { console.assert(false); }
            ret_data.expirationDate = new_end_date.format('YYYY-MM-DD')
        }

        if (data.subject === 'EDULAB') {
            ret_data.numSeats = data.numSeats;
        }

        return ret_data;
    };
    return (
        <Create {...props} transform={transform}>
            <SimpleForm>
                <ReferenceInput label="Email" source="userId" reference="users" validate={required()}>
                    <AutocompleteInput
                        optionText="email"
                        suggestionLimit="5"
                        filterToQuery={filterToQuery}
                        fullWidth
                    />
                </ReferenceInput>
                <SelectInput source="subject" choices={licenseTypes} defaultValue="NFR" validate={required()}/>
                <DateInput label="Start Date (empty == today)" source="startDate" fullWidth/>
                <FormDataConsumer>
                    {({ formData, ...rest }) => hasDuration(formData.subject) &&
                        <div>
                            <BooleanInput label="No Expiration" source="noExpiration" />
                        </div>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => !formData.noExpiration && hasDuration(formData.subject) &&
                        <div>
                            <NumberInput source="count" label="Count" disabled={formData.noExpiration} defaultValue={1}/>
                            <SelectInput source="duration" choices={[
                                { id: 'months', name: 'months' },
                                { id: 'years', name: 'years' },
                            ]} defaultValue="years" validate={required()}/>
                        </div>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => isMusio1(formData.subject) &&
                        <div>
                            <Musio1VersionInput source="perpetualVersion" validate={required()}/>
                        </div>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.subject == 'EDULAB' &&
                        <NumberInput source="numSeats" label="Number of Seats" validate={required()}/>
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
}
